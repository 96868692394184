import React, { Component } from 'react';
import './css/Top.css';

class Top extends Component{

    render(){
        return(
            <div id="Top">
                <div className="container">
                    <h1>Nyu Atsushi</h1>
                    <h2>Engineering Portfolio Site</h2>
                </div>
            </div>
        )
    }
}

export default Top;